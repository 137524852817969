<template>
  <div>
    <div class="flex next-btn">
      <div>
        <div style="font-size: 36px;">{{ $t("param.a4") }}</div>
      </div>
      <div>
        <!--打印-->
        <el-button v-print="'printObj'" type="primary" @click="printClick"
                   v-loading.fullscreen.lock="fullscreenLoading">{{ $t('common.a12') }}
        </el-button>
        <!--权限控制-->
        <el-button v-if="quanxian == 'Config' || quanxian == 'Admin'" v-show="edit == 'true'" size="large"
                   type="primary"
                   @click="save">{{ $t('common.a4') }}
        </el-button>
      </div>
    </div>
    <!--打印区域-->
    <div id="print_html" ref="print_html">
      <el-table
          :data="tableData"
          class="table mar30"
          ref="table"
          border
          @select-all="selectAll"
          style="width: 100%;">
        <el-table-column
            type="selection"
            width="60">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.value" @change="checkChange(true)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
            prop="key"
            :label="$t('param.a88')">
          <template slot-scope="scope">
            {{ $t('menuData.' + scope.row.key) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- button -->
    <div class="flex mar30 next-btn" v-show="edit != 'true'">
      <div>
        <el-button size="large" type="primary" @click="back">{{ $t('common.a5') }}</el-button>
      </div>
      <el-button size="large" type="primary" @click="save">{{ $t('common.a6') }}</el-button>
    </div>
  </div>
</template>

<script>
// import html2canvas from 'html2canvas'
// import printJS from 'print-js'

export default {
  data() {
    return {
      pid: '',
      id: '',
      edit: false,
      check: false,//用来判断在编辑状态下是否有未保存的更改
      havegsmr_vc: 0,
      initiate_rec: 0,
      tableData: [
        {key: 'gsmr_mtln_dial', value: false},
        {key: 'gsmr_tcln_dial', value: false},
        {key: 'gsmr_vc', value: false}, //This item should be removed if virtual_channel_enable in BasicParameters is not actived.
        {key: 'gsmr_mtln', value: false},
        {key: 'gsmr_tcln', value: false},
        {key: 'gsmr_init_vbs', value: false},
        {key: 'gsmr_init_rec', value: false}, //This item should be removed if initiate_rec in BasicParameters is not actived.
        {key: 'gsmr_mng_vbs', value: false},
        {key: 'gsmr_set_rec', value: false},  //This item should be removed if initiate_rec in BasicParameters is not actived.
        //{key: 'gsmr_mng_fn', value: false},
        {key: 'gsmr_call_pro', value: false},
        {key: 'gsmr_auto_answer', value: false},
        {key: 'message', value: false},
        {key: 'my_folders', value: false},
        {key: 'browser', value: false},
        {key: 'del_call_log', value: false},
        {key: 'reset_call_log_time', value: false},
        {key: 'del_miss_call_log', value: false},
        {key: 'del_call_history', value: false},
        {key: 'settings_gprs', value: false},
        {key: 'settings_ring', value: false},
        {key: 'settings_profile', value: false},
        {key: 'settings_call_settings', value: false},
        {key: 'settings_bluetooth', value: false},
        {key: 'settings_about', value: false},
        {key: 'settings_auto_lock', value: false},
        {key: 'settings_wupn', value: false},
        {key: 'settings_cell_lock', value: false},
        {key: 'settings_cell_mnt', value: false},
        {key: 'settings_language', value: false},
        {key: 'settings_quick_dial', value: false},
        {key: 'settings_reset', value: false},
        {key: 'settings_usb_mode', value: false},
        {key: 'settings_network_frequency', value: false},
      ],
      quanxian: '',
      printObj: {
        id: 'print_html', //打印标签的id
      },
      fullscreenLoading: false,
    }
  },
  created() {
    this.edit = sessionStorage.getItem('edit');
    if (this.edit == 'false') {// 如果是新增，用户填写数据没有点击下一步保存的情况下做一个缓存的回显
      let data = sessionStorage.getItem('menuCache');
      if (data && data != 'null') this.tableData = JSON.parse(data)
      sessionStorage.removeItem('menuCache')
    }
    let data = sessionStorage.getItem('file')
    if (data) {
      this.pid = JSON.parse(data).id;
      this.havegsmr_vc = JSON.parse(data).virtual_channel_enable;
      this.initiate_rec = JSON.parse(data).initiate_rec;
      if (this.havegsmr_vc != 'true' && this.havegsmr_vc !== true) {//关联基本信息，是否去掉关联数据
        this.tableData.splice(this.tableData.findIndex(v => v.key == 'gsmr_vc'), 1)
      }
      if (this.initiate_rec != 'true' && this.initiate_rec !== true) {//关联基本信息，是否去掉关联数据
        this.tableData.splice(this.tableData.findIndex(v => v.key == 'gsmr_init_rec'), 1)
        this.tableData.splice(this.tableData.findIndex(v => v.key == 'gsmr_set_rec'), 1)
      }
    }
    this.getList();

    let user = sessionStorage.getItem('user');
    if (user) this.quanxian = JSON.parse(user).quanxian;
  },
  mounted() {
  },
  beforeRouteLeave(to, from, next) {
    if (this.edit == 'true' && this.check) {// 如果是编辑的时候没有保存，加一个提示
      this.$confirm(this.$t('message.a13'), this.$t('message.a10'), {
        confirmButtonText: this.$t('common.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        if (this.save(
          res =>{
            next();
          }
          ) === false) {
          next(false)
        } else {
          //next();
        }
      }).catch(() => {
        next()
      });
    } else if (this.edit == 'true' || (
        to.path == '/Contacts' ||
        to.path == '/BasicParameters' ||
        to.path == '/Menus' ||
        to.path == '/Shortcutkeys' ||
        to.path == '/ForbiddenNumbers'
    )) {
      if (this.edit == 'false') {// 如果是新增，离开当前页面做一下缓存，不用重新选择了
        sessionStorage.setItem('menuCache', JSON.stringify(this.tableData))
      }
      return next();
    } else {
      this.$confirm(this.$t('message.a11'), this.$t('message.a10'), {
        confirmButtonText: this.$t('cp.a4'),
        cancelButtonText: this.$t('cp.a5'),
        type: 'warning'
      }).then(() => {
        if (this.pid) {
          this.postRequest('index/del_phone_profile', {ids: this.pid}, () => {
            this.$message({
              type: 'success',
              message: this.$t('message.a6')
            });
          })
        }
        next();
      }).catch(() => {
        next(false)
      });
    }
  },
  methods: {
    printClick() {
      this.fullscreenLoading = true;
      this.html2canvas(this.$refs.print_html, {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then((canvas) => {
        const url = canvas.toDataURL()
        this.printJS({
          printable: url,
          type: 'image',
          documentTitle: ''
        })
        this.fullscreenLoading = false;
      });
    },
    getList() {
      let params = {
        uid: sessionStorage.getItem('id'),
        pid: this.pid,
      }
      this.postRequest('index/get_menus', params, res => {
        if (res.data) {
          this.id = res.data.id
          delete res.data.id;
          delete res.data.uid;
          let arr = [];
          for (let i in res.data) {
            if (i == 'phone_profile_id') continue;
            /* remove Manage FN temporarily,it should be removed in db table fa_menus */
            if (i == 'gsmr_mng_fn') continue;
            arr.push({
              key: i,
              value: res.data[i] && (res.data[i] == 'true' || res.data[i] == '1') ? true : false
            })
          }
          if (this.havegsmr_vc != 'true' && this.havegsmr_vc !== true) {//关联基本信息，是否去掉关联数据
            arr.splice(arr.findIndex(v => v.key == 'gsmr_vc'), 1)
          }
          if (this.initiate_rec != 'true' && this.initiate_rec !== true) {//关联基本信息，是否去掉关联数据
            arr.splice(arr.findIndex(v => v.key == 'gsmr_init_rec'), 1)
            arr.splice(arr.findIndex(v => v.key == 'gsmr_set_rec'), 1)
          }
          this.tableData = arr;

          //如果是全选的状态，调用方法展示全选状态
          if (this.tableData.every(v => v.value == 'true' || v.value == true)) {
            this.$refs.table.toggleAllSelection();
          }
        }
      })
    },
    back() {
      this.$root.status = 'Contacts';
      this.$router.go(-1)
    },
    save(callback) {
      let params = {
        pid: this.pid,
        uid: sessionStorage.getItem('id'),
      }
      if (this.id) params.id = this.id;
      this.tableData.forEach(v => {
        params[v.key] = v.value ? v.value : false;
      })
      if (this.havegsmr_vc != 'true' && this.havegsmr_vc !== true) {//关联基本信息，是否去掉关联数据
        //this.tableData.splice(this.tableData.findIndex(v => v.key == 'gsmr_vc'), 1)
        params['gsmr_vc'] = false 
      }
      if (this.initiate_rec != 'true' && this.initiate_rec !== true) {//关联基本信息，是否去掉关联数据
        //this.tableData.splice(this.tableData.findIndex(v => v.key == 'gsmr_init_rec'), 1)
        params['gsmr_init_rec'] = false 
        //this.tableData.splice(this.tableData.findIndex(v => v.key == 'gsmr_set_rec'), 1)
        params['gsmr_set_rec'] = false
      }
      this.postRequest('index/set_menus', params, res => {
        if (this.edit == 'true' && this.pid) {
          // this.$message({
          //   type: 'success',
          //   message: this.$t('message.a5')
          // })
          this.uploadXML();
        } else if (res.data) {
          this.$root.status = 'Shortcutkeys'
          this.$router.push('/Shortcutkeys?id=' + res.data)
        }
        if (callback){
          callback()
        }
      }, true)
    },
    uploadXML() {
      this.postRequest('index/create_xml', {
        id: this.pid,
        uid: sessionStorage.getItem('id')
      }, () => {
        this.$message({
          type: 'success',
          message: this.$t('message.a5')
        });
        this.check = false;
      })
    },
    selectAll(selection) {
      let sel = selection.length > 0;
      this.tableData.forEach(v => v.value = sel);
      this.check = true;
    },
    checkChange(cont) {
      console.log(cont, 'cont')
      cont === true ? this.check = true : '';
    }
  }
}
</script>

<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;

}

.search:focus-visible {
  outline: none;
}

.search-btn {
  width: 11%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.next-btn {
  margin-bottom: 30px;
}

.mar30 {
  margin-top: 30px;
}
</style>
